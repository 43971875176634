#dynamic-room-description {
  margin-bottom: 1em;
}

div.showcase-entry {
  border-style: solid;
  padding: 5px;
  border-width: thin;
  margin-block-end: 5px;
  min-width: 650px;
  max-height: 600px;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
}

a.room-link {
  color: var(--link);
}

a.room-link:visited {
  color: var(--link);
}

#join-video-chat {
  vertical-align: top;
  margin-bottom: 0.5em;
}

#toggle-text-only-mode {
  margin-left: 2em;
  vertical-align: top;
}

#iframes {
  border: solid;
  border-color: var(--highlight-line);
  width: min-content;
}

#captions {
  border-top: dashed;
  border-color: var(--highlight-line);
  padding-top: 5px;
}

#big-reconfigure-media-selector {
  margin-left: 0.8em;
  color: white;
}

#full-room-description {
  display: block;
  background-color:var(--main-background);
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  transition: max-height 0.15s ease-in;
  max-height: 999px; /* Needed for the transition */
}

#full-room-description.collapsed {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 0.15s ease-in;
  margin: 0;
  margin-top: 1em;
}

#room-collapse-button {
  margin-left: 2em;
  font-size: 0.5em;
  font-weight: strong;
}

#room-name {
  margin-bottom: 0;
}

#dynamic-room-description .has-audio, #dynamic-room-description .has-video {
  padding-left: 0.5em;
  cursor: pointer;
}